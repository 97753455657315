<template>
	<component
		:is="type"
		:class="classes"
	>
		<!-- @slot Default slot for text -->
		<slot />
	</component>
</template>

<script>
import sizes from "./definitions/sizes";
import type from "./definitions/type";

/**
 * The heading-component should be used instead of these tags; h1-h6
 * @displayName TnHeading
 */
export default defineComponent({
	name: "TnHeading",

	props: {
		/**
		 * The semantic html tag the component will be rendered as
		 * @values h1, h2, h3, h4, h5, h6
		 */
		type: {
			type: String,
			default: "h1",
			validator: (value) => {
				return type.includes(value.toLowerCase());
			},
		},
		/**
		 * Size that will be rendered. Can be used in combination with 'type' to override defaults where it makes sense semantically.
		 * @values 2xs, xs, s, m, l, xl, 2xl
		 */
		size: {
			type: String,
			default: null,
			validator: (value) => {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Heading with font-weight set to bold
		 */
		bold: {
			type: Boolean,
			default: false,
		},
		/**
		 * Will text-align center the heading
		 */
		center: {
			type: Boolean,
			default: false,
		},
		/**
		 * Responsive will shrink font-size one size down on mobile
		 */
		responsive: {
			type: Boolean,
			default: false,
		},
		/**
		 * Dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		classes() {
			const typography = this.size ? `title-${this.size}` : `title-${this.semanticMapping}`;
			return [
				"tn-heading",
				typography,
				{ bold: this.bold },
				{ center: this.center },
				{ responsive: this.responsive },
				{ dark: this.dark },
			];
		},
		semanticMapping() {
			if (this.type && !this.size) {
				switch (this.type) {
					case "h1":
						return "xl";
					case "h2":
						return "m";
					case "h3":
						return "s";
					case "h4":
					case "h5":
						return "xs";
					case "h6":
						return "2xs";
				}
			}
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/mixins" as mixins;
@use "@/assets/typography/scss/responsive" as responsive;
@use "@/assets/scss/variables" as variables;

.tn-heading {
	--text-color: inherit;

	font-family: variables.$font-family-telenor-base;
	font-weight: normal;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
	border: 0;
	color: var(--text-color);

	&.center {
		text-align: center;
	}

	&.dark {
		--text-color: #{variables.$color-neutrals-white};
	}

	/* Size values */
	&.title-2xs {
		@include mixins.font-title-2xs;

		&.bold {
			@include mixins.font-title-bold-2xs;
		}

		&.responsive {
			@include responsive.font-title-2xs;

			&.bold {
				@include responsive.font-title-bold-2xs;
			}
		}
	}

	&.title-xs {
		@include mixins.font-title-xs;

		&.bold {
			@include mixins.font-title-bold-xs;
		}

		&.responsive {
			@include responsive.font-title-xs;

			&.bold {
				@include responsive.font-title-bold-2xs;
			}
		}
	}

	&.title-s {
		@include mixins.font-title-s;

		&.bold {
			@include mixins.font-title-bold-s;
		}

		&.responsive {
			@include responsive.font-title-s;

			&.bold {
				@include responsive.font-title-bold-s;
			}
		}
	}

	&.title-m {
		@include mixins.font-title-m;

		&.bold {
			@include mixins.font-title-bold-m;
		}

		&.responsive {
			@include responsive.font-title-m;

			&.bold {
				@include responsive.font-title-bold-m;
			}
		}
	}

	&.title-l {
		@include mixins.font-title-l;

		&.bold {
			@include mixins.font-title-bold-l;
		}

		&.responsive {
			@include responsive.font-title-l;

			&.bold {
				@include responsive.font-title-bold-l;
			}
		}
	}

	&.title-xl {
		@include mixins.font-title-xl;

		&.bold {
			@include mixins.font-title-bold-xl;
		}

		&.responsive {
			@include responsive.font-title-xl;

			&.bold {
				@include responsive.font-title-bold-xl;
			}
		}
	}

	&.title-2xl {
		@include mixins.font-title-2xl;

		&.bold {
			@include mixins.font-title-bold-2xl;
		}

		&.responsive {
			@include responsive.font-title-2xl;

			&.bold {
				@include responsive.font-title-bold-2xl;
			}
		}
	}

	@media screen and (max-width: variables.$size-screen-phone) {
		hyphens: auto;
	}
}
</style>
